import { Layout } from '../components/layout'
import { PageHeader } from '../components/pageHeader'
import * as React from 'react'
import { BsClipboardData, BsSnow, FaWind } from 'react-icons/all'
import { ServiceLink } from '../components/serviceLink'

function Lab27Services () {
  return (
    <>
      <div className="flex flex-col items-center w-full">
        <div className="flex flex-col container max-w-md mt-10 mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
          <ul className="flex flex-col divide-y dark:divide-gray-700 w-full">
            <ServiceLink
              title={'Vacuum Pi'}
              description={'Controlling and measuring vacuum sensors.'}
              href={'https://lab.lasnq.physnet.uni-hamburg.de/vacuum'}
              icon={<FaWind className="scale-150" />}
            />
            <ServiceLink
              title={'Cryo Pi'}
              description={'Controlling and measureing the cryostat'}
              href={'https://lab.lasnq.physnet.uni-hamburg.de/cryo'}
              icon={<BsSnow className="scale-150" />}
            />
            <ServiceLink
              title={'Logger Pi'}
              description={
                'Logging data for the Helium Accommodation experiment'
              }
              href="https://lab.lasnq.physnet.uni-hamburg.de/lab27/logger"
              icon={<BsClipboardData className="scale-150" />}
            />
          </ul>
        </div>
      </div>
    </>
  )
}

export default function Lab27Page () {
  return (
    <Layout>
      <PageHeader>Lab 27</PageHeader>
      <div className="container flex flex-col px-6 py-4 mx-auto space-y-6 lg:h-[32rem] lg:py-16 lg:flex-row items-baseline">
        <Lab27Services />
      </div>
    </Layout>
  )
}
