import * as React from 'react'

export function ListArrowRight (color = 'white') {
  return (
    <svg
      width="20"
      fill={color}
      height="20"
      className="hover:text-gray-800 dark:hover:text-white dark:text-gray-200 text-gray-500"
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
    </svg>
  )
}

export function WikiIcon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#000"
      width="32"
      height="32"
      viewBox="0 0 64 64"
    >
      <path d="M32 57.6v1.561a4 4 0 0 1-5.961 3.486l-24-13.5A4 4 0 0 1 0 45.661V18.339a4 4 0 0 1 2.039-3.486l24-13.5A4 4 0 0 1 32 4.839V6.4l2.851-.855A4 4 0 0 1 40 9.376V11l3.504-.438A4 4 0 0 1 48 14.531v34.938a4 4 0 0 1-4.496 3.969L40 53v1.624a4 4 0 0 1-5.149 3.831L32 57.6zm0-4.176l4 1.2V9.376l-4 1.2v42.848zm8-38.393v33.938l4 .5V14.531l-4 .5zM0 44.469V19.531v24.938zm4-26.129v27.321l24 13.5V4.839l-24 13.5zM8 21l4-2v26l-4-2V21z" />
    </svg>
  )
}

export function InventoryIcon () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32.521" height="28.327">
      <path fill="#566270" d="M6.654 24.335h21.863v3.992H6.654z" />
      <path
        d="M0 18.251h23.955v3.992H0zm25.222 0h6.464v3.992h-6.464z"
        fill="#4570a9"
      />
      <path
        d="M4.563 12.167H16.16v3.992H4.563zm13.165 0h5.228v3.992h-5.228z"
        fill="#00a2ce"
      />
      <path fill="#6acaff" d="M8.365 6.084h19.392v3.992H8.365z" />
      <path
        d="M7.319 0h16.35v3.992H7.319zM.285 0h5.228v3.992H.285z"
        fill="#9addff"
      />
    </svg>
  )
}
