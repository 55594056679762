import { ListArrowRight } from '../images/icons'
import * as React from 'react'
import PropTypes from 'prop-types'

export function ServiceLink (props) {
  return (
    <>
      <li className="flex flex-row">
        <a href={props.href} className={'w-full'}>
          <div className="select-none cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 flex flex-1 items-center p-4">
            <div className="flex flex-col w-10 h-10 justify-center items-center mr-4">
              <div className="block relative">{props.icon}</div>
            </div>
            <div className="flex-1 pl-1">
              <div className="font-medium dark:text-white">{props.title}</div>
              <div className="text-gray-600 dark:text-gray-200 text-sm">
                {props.description}
              </div>
            </div>
            <div className="flex flex-row justify-center">
              <button className="w-10 text-right flex justify-end">
                <ListArrowRight />
              </button>
            </div>
          </div>
        </a>
      </li>
    </>
  )
}

ServiceLink.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.node
}
